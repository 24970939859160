<template>
  <div class="loan_item">
    <div class="inner inner1">
      <div class="content">
        <div class="left_cell">
          <h2>Join thousands of satisfied</h2>
          <h2>customers who have chosen</h2>
          <h2>KK Cash for their lending needs.</h2>
          <p class="inner1_text">Apply now and get the cash you need, when you need it, with KK Cash!</p>
        </div>
        <div class="right_cell">
          <img src="~@/assets/images/loanImg/about_us2.png">
        </div>
      </div>
    </div>
    <div class="inner inner4">
      <!-- <h3>What our customers say</h3> -->
      <!-- <p>Tenemos 4.7 de 5 estrellas en Google Play y más de 100 k de reseñas de 5 estrellas en todo el mundo.</p> -->
      <!-- <p><img src="@/assets/images/loanImg/star2.png">4.5</p> -->
      <div class="flex jc-c">
        <div class="carousel-container">
          <div class="carousel">
            <transition-group name="fade" tag="div" class="carousel-wrapper">
              <div v-for="(item) in visibleItems" :key="item.id" class="carousel-item flex fd-c">
                <img class="tx" :src="item.img">
                <div>
                  <h1>{{ item.name }}</h1>
                  <!-- <h2>{{ item.txt }}</h2> -->
                  <!-- <h3>{{ item.name2 }}</h3> -->
                  <p>{{ item.txt2 }}</p>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
      <!-- <div class="flex jc-c">
        <button class="carousel-button" :disabled="currentIndex === 0" @click="previousSlide"><img src="@/assets/images/loanImg/black_left.png"></button>
        <button class="carousel-button" :disabled="currentIndex >= items.length - 3" @click="nextSlide"><img src="@/assets/images/loanImg/black_right.png"></button>
      </div> -->
    </div>
    <div class="inner inner3">
      <div class="content left_item">
        <img class="ng" src="@/assets/images/loanImg/inner2_bg.png">
        <h1>Download the KK Cash app now and experience
          the convenience and reliability of borrowing
          money for your financial needs. We’re here to
          support you when you need it the most!</h1>
        <div class="down" @click="downloadApp()">
          <img src="@/assets/images/loanImg/Google-play.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        // 您的轮播内容数据
        { id: '1', name: 'Fatima', name2: '¡Fue increíble!', txt: 'Profesor', txt2: '“KK Cash is a lifesaver! I needed some \n extra cash for an emergency and their \n app made it so easy to apply for a loan. \n The process was quick and the money \n was in my account within a few hours. \n Highly recommend!”', img: require('@/assets/images/Product01.png') },
        { id: '2', name: 'Amina', name2: 'Seguro y rápido', txt: 'Contable', txt2: '“I’ve tried a few different loan apps in \n Nigeria, but KK Cash is by far the \n best one. The app is user-friendly and \n the loan terms are reasonable. Plus, \n the customer service team is very \n helpful and responsive. Great app!”', img: require('@/assets/images/Product02.png') },
        { id: '3', name: 'Ibrahim', name2: '¡Muchas gracias!', txt: 'Conductor', txt2: '“KK Cash is my go-to app whenever I \n need a quick loan. The application \n process is straightforward and they \n offer flexible repayment options. The \n interest rates are also competitive \n compared to other apps I’ve used. \n Definitely recommend trying it out!”', img: require('@/assets/images/Product03.png') },
        { id: '4', name: 'Abdullahi', name2: 'Gran producto', txt: 'Líder de línea', txt2: '“I’ve been using KK Cash for a \n while now and I have to say, it’s \n been a great experience. The app \n is easy to navigate and they have \n excellent customer service. I also \n like how they offer higher loan \n amounts for repeat borrowers. \n Keep up the good work, KK Cash!”', img: require('@/assets/images/Product04.png') }
      ],
      visibleItemCount: 4,
      currentIndex: 0
    }
  },
  computed: {
    visibleItems() {
      return this.items.slice(this.currentIndex, this.currentIndex + this.visibleItemCount)
    }
  },
  methods: {
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=kk.cash.loan.credit')
    }
  }
}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    margin: 0 auto;
    overflow: hidden;
    h3{
      font-size: 18px;
      color: #333;
    }
    .content{
      padding: 0px calc((100% - 1100px) / 2) 0;
      display: flex;
    }
    .left_cell{
      float: left;
      padding: 60px 0;
      text-align: left;
      h1{
        font-size: 40px;
        margin-bottom: 30px;
      }
      .inner1_text{
        color: #FFFFFF90;
        line-height: 25px;
        margin: 40px 0;
        font-size: 14px;
      }
      p{
        font-size: 12px;
        line-height: 30px;
        color: #999999;
      }
      .cell_item{
        width: 267px;
        height: 140px;
        margin-top: 18px;
        border-bottom: 1px solid #EE7E01;
      }
    }
  }
  .bottom-20{
    margin-bottom: 20px;
  }
  .inner1{
    padding: 70px 0 0;
    // background: url("~@/assets/images/loanImg/inner1_3.png") no-repeat;
    // background-size: 100% 100%;
    background: linear-gradient(#F19A47, #EF684B);
    .content{
      display: flex;
      padding: 0px calc((100% - 1300px) / 2) 0;
      .left_cell,.right_cell{
        flex: 1;
      }
      .left_cell{
        flex: 1.3;
        padding: 90px 30px 40px 50px;
        // background: linear-gradient(to bottom,#A4C519 0% ,#A4C519 5%, #ED7E00 5% ,#ED7E00 100%);
        h2{
          text-align: left;
          color: white;
          margin-bottom: 10px;
          font-size: 35px;
          span{
            font-size: 30px;
            color: #FFDE44;
          }
        }
      }
      .right_cell{
        flex: 1.5;
        img{
          // margin: 0px auto 25px!important;
          width: 700px;
          height: 440px;
          display: block;
        }
      }
    }
  }
  .inner3{
    display: flex;
    flex-direction: column;
    height: 250px;
    background: #D6D6D6;
    .left_item{
      background-size: 250px 100%;
      background-position-x: 240px;
      position: relative;
      display: flex;
      align-items: center;
      h1{
        font-weight: bold;
        line-height: 28px;
        font-size: 18px;
        margin: 0 60px;
      }
      .down{
        img{
          display: block;
          width: 180px;
          margin: 0 auto;
        }
        p{
          font-weight: bold;
          font-size: 16px;
        }
      }
      .ng{
        width: 275px;
        // height: 100%;
      }
    }
  }
  .inner4{
    background: #FFF;
    padding: 50px calc((100% - 1250px) / 2) 60px;
    h3{
      font-size: 30px;
      text-align: center;
      // margin-bottom: 20px;
      position: relative;
      margin-bottom: 30px;
      // &::after{
      //   display: block;
      //   content:'';
      //   width: 80px;
      //   height: 5px;
      //   background: #00BB53;
      //   margin: 20px auto 0;
      // }
    }
    p{
      font-size: 14px;
      color: #666666;
      text-align: center;
      line-height: 28px;
      margin-bottom: 40px;
      img{
        width: 180px;
        height: 25px;
        vertical-align:top !important;
        margin-right: 15px;
      }
    }
    .carousel-container {
      position: relative;
      width: 100%;
      height: 350px;
    }
    .carousel {
      display: flex;
      overflow: hidden;
      height: 100%;
    }
    .carousel-wrapper {
      display: flex;
      width: 100%;
      justify-content:space-around;
      align-items: center;
      padding: 20px 0 0 0;
    }
    .carousel-item {
      flex: 1;
      height: 280px;
      display: flex;
      align-items: center;
      background:#F3F3F3;
      border-radius: 20px;
      font-size: 18px;
      padding: 0px 15px 10px;
      margin: 0 8px;
      .stars{
        img{
          width: 14px;
          height: 14px;
        }
      }
      h1{
        color: black;
        font-size: 15px;
        line-height: 18px;
        text-align:  center;
        font-weight: bold;
        margin: 25px 0;
      }
      h2{
        color: #999999;
        font-size: 12px;
        line-height: 30px;
        text-align:  center;
        font-weight: 500;
      }
      h3{
        color: black;
        font-size: 15px;
        line-height: 18px;
        text-align:  center;
        font-weight: bold;
        margin-top: 15px;
        margin-bottom: 10px;
      }
      img{
        width: 70px;
        height: 70px;
        margin-top: -30px;
      }
      p{
        color: #666666;
        height: 100px;
        font-size: 12px;
        text-align: left;
        font-weight: normal;
        line-height: 20px;
        margin-bottom: 0;
      }
    }
    .carousel-button {
      // position: absolute;
      // padding: 20px;
      font-size: 20px;
      border: none;
      background-color: transparent;
      color: #555;
      outline: none;
      margin: 10px 5px;
      cursor: pointer;
      img{
        width: 30px;
      }
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.2s, transform 0.2s;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
      transform: translateX(200px);
    }
  }
}
@media only screen and (max-width: 800px){
  .loan_item{
    margin-top: 70px;
    padding-bottom: 00px;
    // .inner{
    //   min-width: calc(100vw) !important;
    // }
    .inner1{
      padding: 10px 20px;
      background: white;
      .content{
        display: flex;
        .left_cell{
          flex: 1;
          padding: 10px 20px;
          h2{
            color: #333 !important;
            font-size: 14px;
            span{
              font-size: 16px;
            }
          }
          .inner1_text{
            margin: 0;
            font-size: 12px;
            color: #333 !important;
          }
        }
        .right_cell{
          flex: 0;
          display: none;
        }
      }
    }
    .inner4{
      padding: 30px 20px 30px;
      position: relative;
      display: none;
      h3{
        text-align: center;
        margin-bottom: 30px;
        line-height: 30px;
      }
      .carousel-container{
        height: 320px;
      }
      .carousel-item{
        min-width: 150px !important;
        width: 45% !important;
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
        h1{
          font-size: 12px;
          padding: 0 !important;
        }
        img{
          margin-bottom: 10px;
        }
        p{
          display: none;
        }
      }
      .carousel-button{
        position: absolute;
      }
    }
    .inner3{
      padding: 20px 20px;
      flex-direction: column;
      margin-bottom:0;
      .left_item{
        // background: #DAC8BD;
        position: relative;
        h1{
          width: 100%;
          font-size: 12px;
          margin: 80px 0 0 0 ;
          text-align: center;
          padding: 0 15px;
        }
        p{
          text-align: center;
          margin-bottom: 20px;
          margin-left: 0 !important;
        }
        div{
          position: absolute;
          top: 15px;
          left: calc(50% - 80px);
          img{
            width: 150px;
            height: 50px;
            margin: 0 auto 5px;
          }
        }
        .ng{
          display: none;
        }
      }
    }
  }
}
</style>
